import {get, postJ} from "@/request"
import {IMember, IMemberParam} from "@/apis/customer/member/types";
import {IPageRes} from "@/apis/page";
import {ISystemAccount} from "@/apis/systemManage/types";

/**
 * 分页查询
 * @param params 查询参数
 */
export const systemAccountQueryApi = (params: ISystemAccount) => get<IPageRes<ISystemAccount[]>>("/golf/systemAccount/query", params)

/**
 * 创建
 * @param params 查询参数
 */
export const systemAccountCreateApi = (params: ISystemAccount) => postJ<IPageRes<ISystemAccount[]>>("/golf/systemAccount/create", params)

/**
 * 查询详情
 * @param params 查询参数
 */
export const systemAccountDetailApi = (id: string) => get(`/golf/systemAccount/detail/${id}`)

/**
 * 修改
 * @param params 查询参数
 */
export const systemAccountModifyApi = (params: ISystemAccount) => postJ<IPageRes<ISystemAccount[]>>("/golf/systemAccount/modify", params)


/**
 * 重置密码
 * @param params 查询参数
 */
export const systemAccountResetApi = (params: any) => postJ("/golf/systemAccount/reset", params)

