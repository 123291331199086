
import {Component, Vue} from "vue-property-decorator";
import {ColumnAlign, ColumnType} from "@/types/m-ui-crud";
import {getSystemAdminApi, systemAdminCreateApi, systemAdminModifyApi, systemAdminDetailApi, systemAccountReset} from "@/apis/core";
import {ISystemAdmin} from "@/apis/core/types";
import {
  systemAccountCreateApi,
  systemAccountDetailApi,
  systemAccountModifyApi,
  systemAccountQueryApi, systemAccountResetApi
} from "@/apis/systemManage";
import {talentSettlementAuditApi} from "@/apis/finance/coachReconcile";
import {Message} from "element-ui";


@Component({})
export default class UserIndex extends Vue {

  //确认密码 检验
  confirmPasswordRules(rule: any, value: any, callback: any) {
    if (value === "") {
      callback(new Error("请再次输入密码"));
    } else if (value !== this.modelForm.pwd) {
      callback(new Error("两次输入密码不一致!"));
    } else {
      callback();
    }
  }

  queryForm: any = {
    page: 1,
    limit: 10
  }
  total: number = 0

  tableData: ISystemAdmin[] = []
  // 角色下拉
  // roleList: IRole[] = []

  // 新增修改表单
  modelForm: ISystemAdmin = {
    status: 1
  }

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: true,
    addTitle: '新增用户',
    editTitle: '编辑用户',
    menu: true,
    editBtn: true,
    delBtn: false,
    dialogWidth: '800px',
    column: [
      {
        label: "ID",
        prop: "id",
        align: 'center',
        width:200,
        addHide: true,
        editHide: true,
      },
      {
        label: "关键字",
        prop: "keyword",
        search: true,
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true,
        placeholder: "账号/姓名"
      },
      {
        label: "账号",
        prop: "account",
        align: 'center',
        rules: [{required: true, message: '请输入账号', trigger: 'blur'},],
        span: 12
      },
      {
        label: "密码",
        prop: 'pwd',
        type: 'password',
        rules: [
          {
            required: true,
            validator: (rule: any, value: any, callback: any) => {
              if (!value) {
                return callback(new Error("请输入密码"));
              } else if (!/[a-zA-Z\d]$/.test(value)) {
                callback(new Error("密码格式不正确!"));
              } else {
                callback();
              }
            },

            trigger: 'blur'
          },
        ],
        span: 12,
        hide: true,
        editHide: true,
      },
      // {
      //   label: "确认密码",
      //   prop: 'confirmPassword',
      //   type: 'password',
      //   rules: [
      //     {
      //       required: true,
      //       validator: this.confirmPasswordRules,
      //       trigger: ["blur"],
      //     },
      //   ],
      //   span: 12,
      //   hide: true,
      //   editHide: true,
      // },
      {
        label: "姓名",
        prop: "realName",
        align: 'center',
        rules: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        span: 12
      },
      {
        label: "最后一次登录时间",
        prop: "editTime",
        align: 'center',
        addHide: true,
        editHide: true
      },

      {
        label: "状态",
        prop: "status",
        align: 'center',
        search: true,
        slot: true,
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
        span: 12,
        rules: [
          {required: true, message: '请选择状态', trigger: 'blur'}
        ]
      },
    ]
  }

  /**
   * 重置
   * @param id
   */
  handleRemove(id: string) {
    this.$confirm('是否重置密码?', '提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      systemAccountResetApi({id}).then(e => {
        if (e) {
          Message.success("重置成功!")
          this.getList()
        }
      })
    }).catch((action ) => {
    });
  }


  getList() {
    systemAccountQueryApi(this.queryForm).then(e => {
      //@ts-ignore
      this.tableData = e.list;
      this.total = e.total
    })
  }

  handleReset (id: string) {
    systemAccountReset(id).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  /**
   * 新增提交
   */
  submitSave(form: any, done: any, loading: any) {
    systemAccountCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改提交
   */
  submitEdit(form: any, done: any, loading: any) {
    systemAccountModifyApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: ISystemAdmin, val: number) {
    //@ts-ignore
    systemAccountModifyApi({id: row.id, status: val}).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  async openAdd () {
    // await this.getRoleList()
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }

  /**
   * 打开编辑
   */
  async openEdit (row: ISystemAdmin, $index: number) {
    // await this.getRoleList()
    systemAccountDetailApi(row.id as string).then(e => {
      let row = e;
      row.roleId = row.roleId || []
      // @ts-ignore
      this.$refs.crudRef.rowEdit(row, $index)
    })
  }

  created() {
    this.getList();
  }
}
